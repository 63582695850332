@import url("./../styles/base.css");

/* Background */

#background-container {
    animation: background-animation 10s infinite;
}

@keyframes background-animation {
    0% {
        background-color: var(--rc-blue);
    }

    16.6666666667% {
        background-color: var(--rc-pink);
    }

    33.3333333333% {
        background-color: var(--rc-orange);
    }

    50% {
        background-color: var(--rc-green);
    }

    66.6666666667% {
        background-color: var(--rc-violet);
    }

    83.3333333333% {
        background-color: var(--rc-red);
    }

    100% {
        background-color: var(--rc-blue);
    }
}

#background {
    position: relative;
    background-image: url("../../assets/DotsBackground.png");
    background-size: 24px 24px;
    background-position: 6px 5px;

    @media (width >= 768px) {
        background-position: 2px 5px;
    }

    margin: 0;
    padding: 0;
    min-height: 100vh;
    padding-top: 12px;

    /* Debugging */

    /* background-image: url("./../assets/GridBackground.png"); 
    background-size: 12px 12px;
    */

}

#content {
    max-width: 1024px;
}

/* Typography */

p {
    max-width: calc(12px * 38);
}

/* Section: Header */

header {
    display: flex;
    flex-flow: column-reverse wrap;

    @media (width >= 768px) {
        flex-direction: row;
    }

    padding: 0 24px;

    @media (width >= 768px) {
        padding: 0 36px;
    }

    padding-bottom: calc(12px * 2);

    @media (width >= 768px) {
        padding-bottom: calc(12px * 1);
    }

    #title {
        flex-grow: 1;

        .title-text {
            max-width: calc(24 * 12);
            padding-top: 24px;

            @media (width >= 768px) {
                padding-top: 0;
            }
        }

        h1 {
            background: linear-gradient(120deg, #0075FF -2.18%, #FF00B8 30.03%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        h1 .break {
            display: block;
        }

        .darken {
            color: var(--rc-blue-dark);
        }

        h2 {
            font-weight: 240;
            font-size: 14px;
            line-height: 24px;
            font-style: normal;
            padding: 0;
            color: var(--rc-blue);
            margin: 6px 0;
        }

    }

    #contact {
        text-align: right;
        display: flex;
        flex-wrap: nowrap;
        padding-top: 0;
        padding-bottom: 12px;

        @media (width >= 768px) {
            padding-top: 24px;
        }

        @media (width >= 768px) {
            padding-top: 0;
        }

        a {
            text-decoration-line: none;

            &:hover {

                text-decoration-line: underline;
            }
        }

        img {
            height: 13px;
            margin-top: 12px;
            margin-right: 4px;
        }

        p {
            color: var(--rc-blue-dark);
        }
    }
}

section,
footer {
    padding: 0 18px;

    @media (width >= 768px) {
        padding: 0 36px;
    }

    padding-bottom: calc(12px * 2);

    @media (width >= 768px) {
        padding-bottom: calc(12px * 2);
    }
}


/* Section: About */

.about {
    ul {
        margin: 0;
        padding: 0;
        margin-top: 12px;
        margin-left: 1px;
        list-style: circle;
        padding-left: 24px;
    }

    p, li {
        margin-top: 12px;
        margin-left: 1px;
        font-size: 14px;
        line-height: 24px;

        strong {
            font-weight: 400;
            text-decoration: underline;
            text-decoration-color: var(--rc-pink);
            text-underline-offset: 2px;
        }

    }

    li {
        margin-top: 0;
    }
}

/* Section: Capabilities */

section#capabilities {
    ul {
        margin: 6px 0;
    }
}

section#capabilities li {
    display: inline;
    font-size: 28px;
    line-height: 36px;
    color: var(--rc-pink);
}

section#capabilities li:not(:last-child)::after {
    content: " / ";
    display: inline;
    color: var(--rc-blue);
}

/* Section: Enquire */

section#enquire {
    padding-left: 0;
    padding-right: 0;

    @media (width >= 768px) {
        display: flex;
        justify-content: space-between;
        padding-left: calc(24px*1.5);
        padding-right: 24px;
        max-width: calc(24px * 37);
    }
}

.enquire-text {
    padding: 0 18px;

    @media (width >= 768px) {
        padding: 0;
    }

    padding-bottom: 24px;
    max-width: calc(12px * 33);
}


#enquire-form {
    transform: rotate(-2deg);
    transition: transform 0.5s ease;

    &.has-user-content {
        transform: rotate(0);
    }

    margin-left: 12px;
    margin-right: 12px;
    margin-top: 24px;

    @media (width >= 768px) {
        margin-top: 48px;
    }

    border-radius: 7px;
    border: 2px solid var(--rc-blue);
    background: rgb(255 255 255 / 80%);
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
    display: flex;
    max-width: calc(12px * 32);
    flex-direction: column;
    align-items: flex-start;

    .messages {
        height: 0;
        overflow: hidden;
    }

    .title-bar {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        border: 2px solid var(--rc-blue);
        background-color: var(--rc-blue);
        color: white;
        padding: 3 0 3px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
        }
    }

    .fields {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        border-bottom: 1px solid var(--rc-blue);

        div {
            padding: 3 7px 3px;
        }

        div:first-child {
            border-right: 1px solid var(--rc-blue, #0075FF);
            text-align: right;
            width: calc(12px * 5);

            @media (width >= 768px) {
                width: 84px;
            }
        }

        input {
            all: unset;
            font: inherit;
            padding: 3 7px 3px;

            /* padding: 8px 7px 0px; */
        }

        input::placeholder {
            font-style: italic;
        }
    }

    .content {
        display: flex;
        padding: 11px 4px 4px 12px;

        /* margin-bottom: 6px; */
        flex-direction: column;
        align-items: flex-end;
        align-self: stretch;

        textarea {
            all: unset;
            width: 100%;
            height: auto;
            min-height: calc(24px*3);

            /* Set a minimum height */
            box-sizing: border-box;
            font-family: inherit;
            overflow: visible;
            padding-right: 12px;
            font-size: 18px;
            line-height: 24px;
        }

        textarea::placeholder {
            font-style: italic;
        }

        button {
            margin-top: 20px;
            background-color: var(--rc-blue);
            color: white;
            padding: 0 12px;
            border-radius: 5px;
            display: inline-block;
            border: none;
            cursor: pointer;
            text-align: center;
            text-decoration: none;
            transition: background-color 0.3s ease;
        }

        button:hover {
            /* background-color: #0056b3; */
        }

        button:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgb(0 123 255 / 25%);
        }

        button:active {
            background-color: #004494;
        }

        button.success {
            color: white;
            background-color: #00BFB2;
        }

        button.disabled {
            background-color: white;
            border: 1px gray solid;
            color: gray;
        }
    }
}

/* Footer */

footer {
    display: flex;
    flex-wrap: wrap;

    /* padding: 0 24px 12px; */
    padding-bottom: 24px;

    p {
        color: var(--rc-blue);
    }

    div:first-child {
        flex-grow: 1;
    }
}

/* Loader */

/* Adapted from: https://projects.lukehaas.me/css-loaders/ */


.sending .loader {
    display: inline-block;
}

.loader {
    display: none;
    margin: 0 10px 0 0;
    text-indent: -9999em;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    background: linear-gradient(left, #fff 10%, rgb(255 255 255 / 0%) 42%);
    background: linear-gradient(left, #fff 10%, rgb(255 255 255 / 0%) 42%);
    background: linear-gradient(left, #fff 10%, rgb(255 255 255 / 0%) 42%);
    background: linear-gradient(left, #fff 10%, rgb(255 255 255 / 0%) 42%);
    background: linear-gradient(to right, #fff 10%, rgb(255 255 255 / 0%) 42%);
    position: relative;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
}

.loader::before {
    width: 50%;
    height: 50%;
    background: #fff;
    border-radius: 100% 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader::after {
    background: var(--rc-blue);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    inset: 0;
}

@keyframes load3 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}