@import url("./reset.css");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


/* Colours */

:root {
    --rc-blue: #0075FF;
    --rc-blue-dark: #2C5C93;
    --rc-blue-light: rgb(0 117 255 / 40%);
    --rc-pink: #FF00B8;
    --rc-orange: #FFA300;
    --rc-peach: #FFC48D;
    --rc-green: #96FFC6;
    --rc-violet: #00C8FF;
    --rc-red: #FF1500;
    --rc-purple: #A04AF3;
}

/* Blue */
.blue {
    color: var(--rc-blue);
}

.bg-blue {
    background-color: var(--rc-blue);
}

.blue-dark {
    color: var(--rc-blue-dark);
}

.bg-blue-dark {
    background-color: var(--rc-blue-dark);
}

.blue-light {
    color: var(--rc-blue-light);
}

.bg-blue-light {
    background-color: var(--rc-blue-light);
}

/* Pink */
.pink {
    color: var(--rc-pink);
}

.bg-pink {
    background-color: var(--rc-pink);
}

/* Orange */
.orange {
    color: var(--rc-orange);
}

.bg-orange {
    background-color: var(--rc-orange);
}

/* Peach */
.peach {
    color: var(--rc-peach);
}

.bg-peach {
    background-color: var(--rc-peach);
}

/* Green */
.green {
    color: var(--rc-green);
}

.bg-green {
    background-color: var(--rc-green);
}

/* Violet */
.violet {
    color: var(--rc-violet);
}

.bg-violet {
    background-color: var(--rc-violet);
}

/* Red */
.red {
    color: var(--rc-red);
}

.bg-red {
    background-color: var(--rc-red);
}

/* Purple */
.purple {
    color: var(--rc-purple);
}

.bg-purple {
    background-color: var(--rc-purple);
}


/* Layout and type */

html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: Inter, sans-serif;
    font-optical-sizing: auto;
    font-weight: 320;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    color: var(--rc-blue);
    line-height: 24px;
    font-size: 14px;
}

h1, h2, h3, h4, h5, h6, p, th, li, label, .p-like {
    font-weight: 240;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    padding: 0;
    color: var(--rc-blue);
    margin: 6px 0;
}

a, a:active, a:visited, strong, code {
    color: var(--rc-blue);
    font-style: normal;
}

code, pre {
    font-family: "Space Mono", monospace;
    font-weight: 400;
    font-style: normal;
    margin: 6px 0;
    padding: 0;
}

h1 {
    color: var(--rc-pink);
    font-size: 42px;
    line-height: 42px;
    letter-spacing: -0.2;
    margin-bottom: 30px;
}

h2 {
    letter-spacing: -0.2;
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 18px;
}

h3, h4, h5, h6, p, li {
    /* padding: 6px 0; */
}

a {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: var(--rc-pink);
}

li {
    padding: 0;
    margin: 0;
}

ul, ol {
    padding: 0;
    margin: 0 0 0 24px;
}

ul.horizontal li {
    display: inline-block;
    padding-right: 6px;
}

strong {
    font-weight: 400;
    text-decoration: underline;
    text-decoration-color: var(--rc-pink);
    text-underline-offset: 2px;
}

input, button, label {
    height: 28px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
}

textarea {
    height: calc(12px * 4);
    margin: 0;
    padding: 0;
}


/* Utility */

.pad {
    margin: 24px;
}

.debug-grid {
    background-image: url("../../assets/GridBackground.png");
    background-size: 24px 24px;
    background-position: 0 0;
}