@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizelegibility;
  min-height: 100vh;
  line-height: 1.5;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font: inherit;
}

:root {
  --rc-blue: #0075ff;
  --rc-blue-dark: #2c5c93;
  --rc-blue-light: #0075ff66;
  --rc-pink: #ff00b8;
  --rc-orange: #ffa300;
  --rc-peach: #ffc48d;
  --rc-green: #96ffc6;
  --rc-violet: #00c8ff;
  --rc-red: #ff1500;
  --rc-purple: #a04af3;
}

.blue {
  color: var(--rc-blue);
}

.bg-blue {
  background-color: var(--rc-blue);
}

.blue-dark {
  color: var(--rc-blue-dark);
}

.bg-blue-dark {
  background-color: var(--rc-blue-dark);
}

.blue-light {
  color: var(--rc-blue-light);
}

.bg-blue-light {
  background-color: var(--rc-blue-light);
}

.pink {
  color: var(--rc-pink);
}

.bg-pink {
  background-color: var(--rc-pink);
}

.orange {
  color: var(--rc-orange);
}

.bg-orange {
  background-color: var(--rc-orange);
}

.peach {
  color: var(--rc-peach);
}

.bg-peach {
  background-color: var(--rc-peach);
}

.green {
  color: var(--rc-green);
}

.bg-green {
  background-color: var(--rc-green);
}

.violet {
  color: var(--rc-violet);
}

.bg-violet {
  background-color: var(--rc-violet);
}

.red {
  color: var(--rc-red);
}

.bg-red {
  background-color: var(--rc-red);
}

.purple {
  color: var(--rc-purple);
}

.bg-purple {
  background-color: var(--rc-purple);
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
  color: var(--rc-blue);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 320;
  line-height: 24px;
}

h1, h2, h3, h4, h5, h6, p, th, li, label, .p-like {
  color: var(--rc-blue);
  margin: 6px 0;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 240;
  line-height: 24px;
}

a, a:active, a:visited, strong, code {
  color: var(--rc-blue);
  font-style: normal;
}

code, pre {
  margin: 6px 0;
  padding: 0;
  font-family: Space Mono, monospace;
  font-style: normal;
  font-weight: 400;
}

h1 {
  color: var(--rc-pink);
  letter-spacing: -.2px;
  margin-bottom: 30px;
  font-size: 42px;
  line-height: 42px;
}

h2 {
  letter-spacing: -.2px;
  margin: 0 0 18px;
  font-size: 32px;
  line-height: 36px;
}

a {
  text-underline-offset: 3px;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--rc-pink);
  text-decoration-color: var(--rc-pink);
}

li {
  margin: 0;
  padding: 0;
}

ul, ol {
  margin: 0 0 0 24px;
  padding: 0;
}

ul.horizontal li {
  padding-right: 6px;
  display: inline-block;
}

strong {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--rc-pink);
  text-decoration-color: var(--rc-pink);
  text-underline-offset: 2px;
  font-weight: 400;
}

input, button, label {
  box-sizing: border-box;
  border: 0;
  height: 28px;
  margin: 0;
  padding: 0;
}

textarea {
  height: 48px;
  margin: 0;
  padding: 0;
}

.pad {
  margin: 24px;
}

.debug-grid {
  background-image: url("GridBackground.814a66b4.png");
  background-position: 0 0;
  background-size: 24px 24px;
}

#background-container {
  animation: 10s infinite background-animation;
}

@keyframes background-animation {
  0% {
    background-color: var(--rc-blue);
  }

  16.6667% {
    background-color: var(--rc-pink);
  }

  33.3333% {
    background-color: var(--rc-orange);
  }

  50% {
    background-color: var(--rc-green);
  }

  66.6667% {
    background-color: var(--rc-violet);
  }

  83.3333% {
    background-color: var(--rc-red);
  }

  100% {
    background-color: var(--rc-blue);
  }
}

#background {
  background-image: url("DotsBackground.d6048ecb.png");
  background-position: 6px 5px;
  background-size: 24px 24px;
  min-height: 100vh;
  margin: 0;
  padding: 12px 0 0;
  position: relative;
}

@media (width >= 768px) {
  #background {
    background-position: 2px 5px;
  }
}

#content {
  max-width: 1024px;
}

p {
  max-width: 456px;
}

header {
  flex-flow: column-reverse wrap;
  padding: 0 24px 24px;
  display: flex;
}

@media (width >= 768px) {
  header {
    flex-direction: row;
    padding: 0 36px 12px;
  }
}

header #title {
  flex-grow: 1;
}

header #title .title-text {
  max-width: 288px;
  padding-top: 24px;
}

@media (width >= 768px) {
  header #title .title-text {
    padding-top: 0;
  }
}

header #title h1 {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(120deg, #0075ff -2.18%, #ff00b8 30.03%);
  -webkit-background-clip: text;
  background-clip: text;
}

header #title h1 .break {
  display: block;
}

header #title .darken {
  color: var(--rc-blue-dark);
}

header #title h2 {
  color: var(--rc-blue);
  margin: 6px 0;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 240;
  line-height: 24px;
}

header #contact {
  text-align: right;
  flex-wrap: nowrap;
  padding-top: 0;
  padding-bottom: 12px;
  display: flex;
}

@media (width >= 768px) {
  header #contact {
    padding-top: 0;
  }
}

header #contact a {
  text-decoration-line: none;
}

header #contact a:hover {
  text-decoration-line: underline;
}

header #contact img {
  height: 13px;
  margin-top: 12px;
  margin-right: 4px;
}

header #contact p {
  color: var(--rc-blue-dark);
}

section, footer {
  padding: 0 18px 24px;
}

@media (width >= 768px) {
  :is(section, footer) {
    padding: 0 36px 24px;
  }
}

.about ul {
  margin: 12px 0 0 1px;
  padding: 0 0 0 24px;
  list-style: circle;
}

.about p, .about li {
  margin-top: 12px;
  margin-left: 1px;
  font-size: 14px;
  line-height: 24px;
}

:is(.about p, .about li) strong {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--rc-pink);
  text-decoration-color: var(--rc-pink);
  text-underline-offset: 2px;
  font-weight: 400;
}

.about li {
  margin-top: 0;
}

section#capabilities ul {
  margin: 6px 0;
}

section#capabilities li {
  color: var(--rc-pink);
  font-size: 28px;
  line-height: 36px;
  display: inline;
}

section#capabilities li:not(:last-child):after {
  content: " / ";
  color: var(--rc-blue);
  display: inline;
}

section#enquire {
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 768px) {
  section#enquire {
    justify-content: space-between;
    max-width: 888px;
    padding-left: 36px;
    padding-right: 24px;
    display: flex;
  }
}

.enquire-text {
  max-width: 396px;
  padding: 0 18px 24px;
}

@media (width >= 768px) {
  .enquire-text {
    padding: 0;
  }
}

#enquire-form {
  border: 2px solid var(--rc-blue);
  background: #fffc;
  border-radius: 7px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 384px;
  margin-top: 24px;
  margin-left: 12px;
  margin-right: 12px;
  transition: transform .5s;
  display: flex;
  transform: rotate(-2deg);
  box-shadow: 0 4px 4px #00000040;
}

#enquire-form.has-user-content {
  transform: rotate(0);
}

@media (width >= 768px) {
  #enquire-form {
    margin-top: 48px;
  }
}

#enquire-form .messages {
  height: 0;
  overflow: hidden;
}

#enquire-form .title-bar {
  border: 2px solid var(--rc-blue);
  background-color: var(--rc-blue);
  color: #fff;
  align-self: stretch;
  align-items: flex-start;
  padding: 3px 0;
  display: flex;
}

#enquire-form .title-bar div {
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

#enquire-form .fields {
  border-bottom: 1px solid var(--rc-blue);
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

#enquire-form .fields div {
  padding: 3px 7px;
}

#enquire-form .fields div:first-child {
  border-right: 1px solid var(--rc-blue, #0075ff);
  text-align: right;
  width: 60px;
}

@media (width >= 768px) {
  #enquire-form .fields div:first-child {
    width: 84px;
  }
}

#enquire-form .fields input {
  all: unset;
  font: inherit;
  padding: 3px 7px;
}

#enquire-form .fields input::placeholder {
  font-style: italic;
}

#enquire-form .content {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  padding: 11px 4px 4px 12px;
  display: flex;
}

#enquire-form .content textarea {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 72px;
  padding-right: 12px;
  font-family: inherit;
  font-size: 18px;
  line-height: 24px;
  overflow: visible;
}

#enquire-form .content textarea::placeholder {
  font-style: italic;
}

#enquire-form .content button {
  background-color: var(--rc-blue);
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 0 12px;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

#enquire-form .content button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #007bff40;
}

#enquire-form .content button:active {
  background-color: #004494;
}

#enquire-form .content button.success {
  color: #fff;
  background-color: #00bfb2;
}

#enquire-form .content button.disabled {
  color: gray;
  background-color: #fff;
  border: 1px solid gray;
}

footer {
  flex-wrap: wrap;
  padding-bottom: 24px;
  display: flex;
}

footer p {
  color: var(--rc-blue);
}

footer div:first-child {
  flex-grow: 1;
}

.sending .loader {
  display: inline-block;
}

.loader {
  text-indent: -9999em;
  background: #fff;
  background: linear-gradient(left, #fff 10%, #fff0 42%);
  background: linear-gradient(left, #fff 10%, #fff0 42%);
  background: linear-gradient(left, #fff 10%, #fff0 42%);
  background: linear-gradient(left, #fff 10%, #fff0 42%);
  background: linear-gradient(to right, #fff 10%, #fff0 42%);
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin: 0 10px 0 0;
  animation: 1.4s linear infinite load3;
  display: none;
  position: relative;
  transform: translateZ(0);
}

.loader:before {
  content: "";
  background: #fff;
  border-radius: 100% 0 0;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.loader:after {
  background: var(--rc-blue);
  content: "";
  border-radius: 50%;
  width: 75%;
  height: 75%;
  margin: auto;
  position: absolute;
  inset: 0;
}

@keyframes load3 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.cc328cf3.css.map */
